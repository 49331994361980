var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-xl":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"lg12":"","sm12":"","xs12":""}},[_c('Search'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.table.header,"items":_vm.table.content,"item-key":'id',"loading":_vm.table.isLoading,"items-per-page":_vm.table.paginate.inputPage.size,"options":_vm.pagination,"server-items-length":_vm.table.total,"footer-props":{ 'items-per-page-options': [10, 20, 30, 40, 50, 100],showFirstLastPage: true, showCurrentPage: true }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.email))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.email))])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }