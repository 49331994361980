<template>
  <v-container grid-list-xl>
    <v-layout
      row
      wrap
    >
      <v-flex
        lg12
        sm12
        xs12
      >
        <Search />
        <v-data-table
          class="elevation-1"
          :headers="table.header"
          :items="table.content"
          :item-key="'id'"
          :loading="table.isLoading"
          :items-per-page="table.paginate.inputPage.size"
          :options.sync="pagination"
          :server-items-length="table.total"
          :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, 100],showFirstLastPage: true, showCurrentPage: true }"
        >
          <template v-slot:item.name="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >{{ item.name }}</span>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.email="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >{{ item.email }}</span>
              </template>
              <span>{{ item.email }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import Search from './Search';

  export default {
    components: {
      Search
    },
    data: () => ({
      pagination: null
    }),
    computed: {
      ...mapState('empresa', ['table'])
    },
    watch: {
      pagination: {
        handler() {
          this.changePagination();
        },
        deep: true
      }
    },
    destroyed() {
      this.clear();
    },
    methods: {
      changePagination() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
        const paginate = {
          ...this.table.paginate,
          inputPage: {
            page: page,
            size: itemsPerPage,
            order: sortBy.length > 0 ? sortBy[0] : 'id',
            descingOrder: sortDesc.length > 0 ? sortDesc[0] : true
          }
        };
        this.changePage(paginate);
        this.search();
      },
      ...mapActions('empresa', { search: 'SEARCH' }),
      ...mapActions('empresa', { clear: 'CLEAR' }),
      ...mapActions('empresa', { changePage: 'CHANGE_PAGE' })
    },
  };
</script>
